export enum PATHS {
  main = '/',
  courses = '/courses',
  course = '/courses/:courseName/*',
  qa_python = '/courses/qa_python',
  frontend = '/courses/frontend',
  python = '/courses/python',
  ios = '/courses/ios',
  unity = '/courses/unity',
  uxui = '/courses/uxui',
  system_analyst = '/courses/system_analyst',
  one_c = '/courses/one_c',
  java = '/courses/java',
  fullstack_csharp = '/courses/fullstack_csharp',
  unreal_engine = '/courses/unreal_engine',
  business_analyst = '/courses/business_analyst',
  game_dev = '/courses/game_dev',
  motion = '/courses/motion',
  devops = '/courses/devops',
  designer = '/courses/designer',

  privacy = 'https://teachmeskills.ru/politika-obrabotki-personalnyh-dannyh',
  vk = 'https://vk.com/teachmeskills',
  telegram = 'https://t.me/TMS_news',
  youtube = 'https://www.youtube.com/channel/UCrpbZ8VZjn8FtMv0-I1VJ2A',
  teachers = 'https://teachmeskills.by/teachers',
  blog = 'https://teachmeskills.by/blog',
}
